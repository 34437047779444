
.nav-tabs {
    border: 0;
    border-bottom: 2px solid #ececec !important;
    text-align: center;
    height: 55px;
    vertical-align: middle;
    width: 100%;
    box-sizing: border-box;
    display: none;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    border: 0;
    color: #17a2b8 !important;
    position: relative;
    padding-left: 0;
}
.nav-tabs .nav-item {
    margin: auto;
    width: auto;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
    border: 0;
    color: #37afc9 !important;
    position: relative;
    padding-left: 0;
}
.nav-tabs .nav-link{
    border: 0;
    font-size: 12px;
    display: inline-block;
    box-sizing: border-box;
    color: #495057 !important;
    position: relative;
    padding-left: 0;
    padding-top: 27px;
    margin-top: 0;
}
.nav-link.ar-icon::before {
    font-size: 25px;
    float: left;
    margin-top: -5px;
}


.nav-responsive {
    border: 0;
    position: absolute;
    vertical-align: middle;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    z-index: 2;
    display: none;
    background: #f5f5f5;
    box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 4px 40px rgb(0 0 0 / 6%);
    padding: 20px;
    right: 0;
}
.nav-responsive .nav-item.show .nav-link, .nav-responsive .nav-link.active{
    color: #17a2b8 !important;
}
.nav-responsive .nav-item {
    width: 100%;
}
.nav-responsive .nav-link:focus, .nav-responsive .nav-link:hover{
    color: #37afc9 !important;
}
.nav-responsive .nav-link{
    border: 0;
    font-size: 16px;
    display: inline-block;
    box-sizing: border-box;
    color: #495057 !important;
    position: relative;
    padding: 13px 0;
}

label.hamburger {
    z-index: 9999;
    display: block;
    height: 55px;
    width: 55px;
    padding: 20px 17px;
    position: absolute;
    right: 0;
    top: -55px;
}
label.hamburger:hover {
    cursor: pointer;
}


label.hamburger > i {
    position: absolute;
    height: 2px;
    border-radius: 2px;
    top: 50%;
    pointer-events: auto;
    width: 24px;
    background-color: #37afc9;
}

label.hamburger > i:before, label.hamburger > i:after {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    left: 50%;
    background-color: #37afc9;
    content: "";
    transition: transform;
    transform-origin: 50% 50%;
    box-sizing: unset !important;
    border-radius: 2px;
}

label.hamburger > i:before {
    transform: translate(-50%, -7px);
}
label.hamburger > i:after {
    transform: translate(-50%, 7px);
}

input.hamburger:checked ~ .nav-responsive {
    display:block;
}

input.hamburger:checked ~ label {
    background-color: #f5f5f5;
}
input.hamburger:checked ~ label > i {
    transform: rotate(-90deg);
}
input.hamburger:checked ~ label > i:before {
    transform: translate(3%, -246%) rotate(45deg);
    width: 15px;
}
input.hamburger:checked ~ label > i:after {
    transform: translate(3%, 246%) rotate(
            -45deg
    );
    width: 15px;
}

@media (min-width: 576px) {
    .card {
        margin-top: 55px;
    }
    .nav-tabs{
        display: none;
    }
    .nav-responsive{
        width: 100%;
    }

    .nav-responsive .nav-link{
        font-size: 16px;
    }

    .nav-link.ar-icon::before {
        font-size: 25px;
    }
    .nav-tabs .nav-link,
    .nav-tabs .nav-link:focus,
    .nav-tabs .nav-link:hover,
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        padding-left: 0;
        padding-top: 27px;
        margin-top: 0;
    }
}

@media (min-width: 768px) {

    .nav-responsive{
        width: 300px;
    }
    .nav-responsive .nav-link{
        font-size: 14px;
    }
    .card {
        margin-top: 55px;
    }
    .nav-tabs{
        display: none;
    }

    .nav-link.ar-icon::before {
        font-size: 23px;
    }
    .nav-tabs .nav-link,
    .nav-tabs .nav-link:focus,
    .nav-tabs .nav-link:hover,
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        padding-top: 7px;
        margin-top: 10px;
    }
}

@media (min-width: 992px) {

    .nav-responsive .nav-link{
        font-size: 14px;
    }
    .card {
        margin-top: 0;
    }
    label.hamburger {
        display: none;
    }

    .nav-tabs{
        display: block !important;
    }
    .nav-responsive{
        display: none;
        visibility: hidden;
    }

    .nav-link.ar-icon::before {
        font-size: 20px;
        float: left;
        margin-top: -5px;
    }
    .nav-tabs .nav-link,
    .nav-tabs .nav-link:focus,
    .nav-tabs .nav-link:hover,
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        padding-top: 7px;
        margin-top: 10px;
    }

}
