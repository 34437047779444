@font-face {
    font-family: "WWType-Bold";
    src: url("../fonts/WWType-Bold.eot"); /* IE9 Compat Modes */
    src: url("../fonts/WWType-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/WWType-Bold.otf") format("opentype"), /* Open Type Font */
    url("../fonts/WWType-Bold.svg") format("svg"), /* Legacy iOS */
    url("../fonts/WWType-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/WWType-Bold.woff") format("woff"), /* Modern Browsers */
    url("../fonts/WWType-Bold.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "WWType";
    src: url("../fonts/WWType-Regular.eot"); /* IE9 Compat Modes */
    src: url("../fonts/WWType-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/WWType-Regular.otf") format("opentype"), /* Open Type Font */
    url("../fonts/WWType-Regular.svg") format("svg"), /* Legacy iOS */
    url("../fonts/WWType-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/WWType-Regular.woff") format("woff"), /* Modern Browsers */
    url("../fonts/WWType-Regular.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "PT Serif";
    src: url("../fonts/PTSerif-Regular.eot"); /* IE9 Compat Modes */
    src: url("../fonts/PTSerif-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/PTSerif-Regular.otf") format("opentype"), /* Open Type Font */
    url("../fonts/PTSerif-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/PTSerif-Regular.woff") format("woff"), /* Modern Browsers */
    url("../fonts/PTSerif-Regular.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}
