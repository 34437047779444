.stepper-circle{
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    float: left;
    margin-right: -11px;
    margin-left: -3px;
    cursor: pointer;
}
.stepper-line{
    width: 43px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    float: left;
}
.stepperBackground{
    width: 100%;
    vertical-align: middle;
    text-align: center;
    height: 100px;
    background-color: #ececec;
    margin: auto;
}
.stepperDiv{
    width: 240px;
    margin: auto;
    margin-top:20px;
    height: 60px;
}



@media (min-width: 576px) {

    .stepperDiv{
        width: 240px;
    }
    .stepper-line{
        width: 43px;
    }
}

@media (min-width: 768px) {
    .stepperDiv{
        width: 430px;
    }
    .stepper-line{
        width: 143px;
    }
}

@media (min-width: 992px) {
    .stepperDiv{
        width: 430px;
    }
    .stepper-line{
        width: 143px;
    }

}