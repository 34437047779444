.MuiPickersToolbar-toolbar {
    background-color: #37afc9 !important;
}
button.MuiButton-textPrimary {
    border-radius: 0 !important;
    color: #37afc9 !important;
    font-weight: bold !important;
    border-color: #37afc9 !important;
    border-width: 2px !important;
    border-style: solid !important;
    background-color: white !important;
    margin-right: 15px !important;
    margin-top: 40px !important;
}
button.MuiButton-textPrimary:hover,button.MuiButton-textPrimary:focus{
    border-radius: 0 !important;
    color: black !important;
    font-weight: bold !important;
    border-color: #37afc9 !important;
    border-width: 2px !important;
    border-style: solid !important;
    background-color: #37afc9 !important;
    margin-right: 15px !important;
    margin-top: 40px !important;
}
button.MuiPickersDay-day {
    border-radius: 0 !important;
}
button.MuiPickersDay-daySelected {
    background-color: #37afc9 !important;
    border-radius: 0 !important;
    color: black !important;
}
p.MuiPickersDay-daySelected {
    color: black !important;
}
.MuiButton-textPrimary {
    color: #171617 !important;
}
