
@font-face {
    font-family: 'adam-riese-icon-font';
    src:  url('../fonts/adam-riese-icon-font.eot?bmw9gn');
    src:  url('../fonts/adam-riese-icon-font.eot?bmw9gn#iefix') format('embedded-opentype'),
    url('../fonts/adam-riese-icon-font.ttf?bmw9gn') format('truetype'),
    url('../fonts/adam-riese-icon-font.woff?bmw9gn') format('woff'),
    url('../fonts/adam-riese-icon-font.svg?bmw9gn#adam-riese-icon-font') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}


.ar-svg {
    font-family: 'adam-riese-icon-font' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-left: 15px;
    font-size: 15px;
    float: left;
    display: block;
    width: 15px;
    height: 15px;
}
.ar-svg path {
    fill: red;

}
.ar-btn-reward {
    transform: rotate(-180deg);
    float: left;
    margin: 2px 0 0 0;
    padding: 0;
}
.ar-btn-reward::before {
    float: left !important;
}
.ar-btn::before {
    font-family: 'adam-riese-icon-font' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-left: 15px;
    font-size: 15px;
    float: none;
}
.ar-btn-big::before {
    font-family: 'adam-riese-icon-font' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-left: 18px;
    font-size: 18px;
    float: none;
}
.ar-icon::before {
    font-family: 'adam-riese-icon-font' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: 5px;
    font-size: 17px;
    color: #37afc9;
    float: none;
}
.ar-icon-mike:before {
    content: "\e95e";
}
.ar-icon-dog-paw-1:before {
    content: "\e958";
}
.ar-icon-dog-paw-2:before {
    content: "\e95a";
}
.ar-icon-dog-paw-3:before {
    content: "\e95b";
}
.ar-icon-dog-paw-4:before {
    content: "\e95c";
}
.ar-icon-dog-paw-5:before {
    content: "\e95d";
}
.ar-icon-desktop-mobile:before {
    content: "\e955";
}
.ar-icon-food:before {
    content: "\e956";
}
.ar-icon-shield-empty:before {
    content: "\e957";
}
.ar-icon-open-external:before {
    content: "\e959";
}
.ar-icon-download-static:before {
    content: "\e954";
}
.ar-icon-download:before {
    content: "\e968";
}
.ar-icon-fire:before {
    content: "\e914";
}
.ar-icon-flash:before {
    content: "\e915";
}
.ar-icon-rainy-cloud:before {
    content: "\e951";
}
.ar-icon-theft:before {
    content: "\e952";
}
.ar-icon-water-tap:before {
    content: "\e953";
}
.ar-icon-bauhandwerk:before {
    content: "\e94a";
}
.ar-icon-einzelhandel:before {
    content: "\e94b";
}
.ar-icon-friseur:before {
    content: "\e94c";
}
.ar-icon-gastro:before {
    content: "\e94d";
}
.ar-icon-gewerbe-allgemein:before {
    content: "\e94e";
}
.ar-icon-hotellerie:before {
    content: "\e94f";
}
.ar-icon-kfz:before {
    content: "\e950";
}
.ar-icon-arrow-left:before {
    content: "\e900";
}
.ar-icon-arrow-right:before {
    content: "\e901";
}
.ar-icon-betterplace:before {
    content: "\e902";
}
.ar-icon-bhv:before {
    content: "\e903";
}
.ar-icon-bike:before {
    content: "\e904";
}
.ar-icon-burger:before {
    content: "\e905";
}
.ar-icon-business:before {
    content: "\e906";
}
.ar-icon-businessman:before {
    content: "\e907";
}
.ar-icon-businesspeople:before {
    content: "\e908";
}
.ar-icon-calendar:before {
    content: "\e909";
}
.ar-icon-camera:before {
    content: "\e90a";
}
.ar-icon-chat-active:before {
    content: "\e90b";
}
.ar-icon-chat:before {
    content: "\e90c";
}
.ar-icon-checkmark:before {
    content: "\e90d";
}
.ar-icon-control:before {
    content: "\e90e";
}
.ar-icon-cross:before {
    content: "\e90f";
}
.ar-icon-digital:before {
    content: "\e910";
}
.ar-icon-disk:before {
    content: "\e911";
}
.ar-icon-doctor:before {
    content: "\e912";
}
.ar-icon-dog:before {
    content: "\e913";
}
.ar-icon-eye:before {
    content: "\e916";
}
.ar-icon-faq:before {
    content: "\e917";
}
.ar-icon-filter:before {
    content: "\e918";
}
.ar-icon-flood:before {
    content: "\e919";
}
.ar-icon-fourperson:before {
    content: "\e91a";
}
.ar-icon-glassbreakage:before {
    content: "\e91b";
}
.ar-icon-hhv:before {
    content: "\e91c";
}
.ar-icon-unf:before {
    content: "\e961";
}
.ar-icon-house-rented-1:before {
    content: "\e91d";
}
.ar-icon-house-rented-2:before {
    content: "\e91e";
}
.ar-icon-house-rented:before {
    content: "\e91f";
}
.ar-icon-hr:before {
    content: "\e920";
}
.ar-icon-info:before {
    content: "\e921";
}
.ar-icon-inprogress:before {
    content: "\e922";
}
.ar-icon-legalcar:before {
    content: "\e923";
}
.ar-icon-lens:before {
    content: "\e924";
}
.ar-icon-less-details:before {
    content: "\e925";
}
.ar-icon-locked-xs:before {
    content: "\e926";
}
.ar-icon-long-arrow:before {
    content: "\e927";
}
.ar-icon-mail:before {
    content: "\e928";
}
.ar-icon-more-details:before {
    content: "\e929";
}
.ar-icon-newspaper:before {
    content: "\e92a";
}
.ar-icon-oneperson:before {
    content: "\e92b";
}
.ar-icon-ops:before {
    content: "\e92c";
}
.ar-icon-palm:before {
    content: "\e92d";
}
.ar-icon-paw:before {
    content: "\e92e";
}
.ar-icon-pbv:before {
    content: "\e92f";
}
.ar-icon-pen-paper:before {
    content: "\e930";
}
.ar-icon-pen:before {
    content: "\e931";
}
.ar-icon-phone-crash:before {
    content: "\e932";
}
.ar-icon-phone-old:before {
    content: "\e933";
}
.ar-icon-phv:before {
    content: "\e934";
}
.ar-icon-plaster:before {
    content: "\e935";
}
.ar-icon-play:before {
    content: "\e936";
}
.ar-icon-preis:before {
    content: "\e937";
}
.ar-icon-robot:before {
    content: "\e938";
}
.ar-icon-rs:before {
    content: "\e939";
}
.ar-icon-shield:before {
    content: "\e93a";
}
.ar-icon-smile:before {
    content: "\e93b";
}
.ar-icon-tap:before {
    content: "\e93c";
}
.ar-icon-threeperson:before {
    content: "\e93d";
}
.ar-icon-time:before {
    content: "\e93e";
}
.ar-icon-tv:before {
    content: "\e93f";
}
.ar-icon-twoperson:before {
    content: "\e940";
}
.ar-icon-upload:before {
    content: "\e941";
}
.ar-icon-usp-clock:before {
    content: "\e942";
}
.ar-icon-usp-light:before {
    content: "\e943";
}
.ar-icon-usp-locked:before {
    content: "\e944";
}
.ar-icon-usp-pen:before {
    content: "\e945";
}
.ar-icon-usp-perfectfit:before {
    content: "\e946";
}
.ar-icon-usp-personal:before {
    content: "\e947";
}
.ar-icon-usp-phv:before {
    content: "\e948";
}
.ar-icon-usp-safety:before {
    content: "\e949";
}

.ar-icon-forward:before {
    content: "";
}
.ar-icon-euro:before {
    content: "€"
}
.ar-icon-cross:before {
    content: "";
}


@media (min-width: 576px) {
    .ar-icon::before {
        left: calc(50% - 18px);
    }
}

@media (min-width: 768px) {
    .ar-icon::before {
        left: 0;
    }
}

@media (min-width: 992px) {
    .ar-icon::before {
        left: 0;
    }
}
