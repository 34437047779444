.form-control, .form-control:focus, .form-control:hover {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    background-color: #ececec;
    min-height: 48px;
    border-bottom: 1px solid #495057;
    border-radius: 0 !important;
    border-top-right-radius: 3px !important;
    border-top-left-radius: 3px !important;
    box-shadow: none !important;
}

select.form-control {
    appearance: none;
}

.selectCell:after {
    content: "\25BE";
    position: absolute;
    font-size: 120%;
    right: 33px;
    top: 11px;
    pointer-events: none;
    color: inherit;
}

.react-datepicker-wrapper:after {
    font-family: 'adam-riese-icon-font' !important;
    content: "\e909";
    position: absolute;
    font-size: 150%;
    right: 33px;
    top: 7px;
    pointer-events: none;
    color: inherit;
}

.form-control.is-invalid {
    background-image: none
}


.was-validated .form-control:invalid, .form-control.is-invalid {
    color: #dc3545 !important;
    border-bottom: 1px solid #dc3545 !important;
}

.was-validated .form-control:valid, .form-control.is-valid {
    color: #28a745 !important;
    border-bottom: 1px solid #28a745 !important;
}

.vmnummer {
    margin: auto;
    display: inline-block;
    width: 300px;
}


.vmnummerError {
    color: #dc3545;
    text-align: left;
    width: 100%;
    display: inline-block;
    padding: 2px 0;
}

.form-check .form-check-label {
    font-size: 12px !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.MuiIconButton-root {
    color: rgba(55, 175, 201, 1) !important;
}

.MuiInput-underline:before {
    border-bottom: 1px solid rgba(55, 175, 201, 1) !important;
}

.MuiInput-underline:after {
    border-bottom: 1px solid #37afc9 !important;
}

.MuiFormControl-marginNormal {
    margin-top: 0 !important;
}

.btn-info {
    color: #37afc9 !important;
    font-weight: bold !important;
    border-color: #37afc9 !important;
    border-width: 3px !important;
    height: 48px;
    padding: 0 30px;
    font-size: 14px;
    border-style: solid !important;
    border-radius: 0 !important;
    background-color: white !important;
    margin-right: 15px !important;
    margin-top: 20px !important;
}

.btn-info:hover, .btn-info:focus {
    color: black !important;
    border-color: #37afc9 !important;
    background-color: #37afc9 !important;
}

.btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info.disabled, .btn-info:disabled {
    color: #37afc9 !important;
    opacity: .4 !important;
    border-color: #37afc9 !important;
    background-color: white !important;
    cursor: unset !important;
}

.btn-weiter-tarif {
    width: calc(100% + 16px) !important;
    margin-left: -8px;
    margin-bottom: -8px;
}

.btn-weiter {
    color: white !important;
    font-weight: bold !important;
    border-color: #37afc9 !important;
    border-width: 3px !important;
    height: 48px;
    padding: 0 30px;
    font-size: 14px;
    border-style: solid !important;
    border-radius: 0 !important;
    background-color: #37afc9 !important;
    margin-right: 15px !important;
    margin-top: 20px !important;
}

.btn-weiter:hover, .btn-weiter:focus {
    color: black !important;
    border-color: #37afc9 !important;
    background-color: #37afc9 !important;
}

.btn-weiter[disabled], .btn-weiter[disabled]:hover, .btn-weiter[disabled]:focus, .btn-weiter.disabled, .btn-weiter:disabled {
    color: white !important;
    opacity: .4 !important;
    border-color: #37afc9 !important;
    background-color: #37afc9 !important;
    cursor: unset !important;
}

.btn-plus {
    width: 44px;
    height: 44px;
    color: #37afc9 !important;
    font-weight: bold !important;
    border-color: #37afc9 !important;
    border-width: 2px !important;
    border-style: solid !important;
    border-radius: 0 !important;
    background-color: white !important;
    margin-right: auto;
    margin-top: auto;
    margin-left: auto;
    margin-bottom: auto;
}

.mini-btn {
    font-size: small !important;
    padding: 0 !important;
    height: 17px !important;
    width: 17px !important;
    line-height: 14px !important;
    margin: 0 5px !important;
    border-width: 1px !important;
}

.spezielleHunderasseSpalte {
    padding-left: 15px !important;
    text-align: left !important;
}

.spezielleHunderasseSpalteWithBtn {
    padding-left: 15px !important;
    padding-top: 10px !important;
    text-align: left !important;
}

.btn-plus:hover, .btn-plus:focus {
    color: black !important;
    border-color: #37afc9 !important;
    background-color: #37afc9 !important;
}

.btn-plus[disabled], .btn-plus[disabled]:hover, .btn-plus[disabled]:focus, .btn-plus.disabled, .btn-plus:disabled {
    color: #37afc9 !important;
    opacity: .4 !important;
    border-color: #37afc9 !important;
    background-color: white !important;
    cursor: unset !important;
}


.btn-anmeldung {
    margin: -89px 0 89px 30px;
}

.btn-hochladen {
    margin-top: 0 !important;
}

.btn-cancel {
    color: #7d7c7f !important;
    font-weight: bold !important;
    height: 48px;
    border-color: #7d7c7f !important;
    border-width: 3px !important;
    border-style: solid !important;
    border-radius: 0 !important;
    background-color: white !important;
    margin-right: 15px !important;
    margin-top: 20px !important;
}

.btn-cancel:hover, .btn-cancel:focus {
    color: black !important;
    border-color: #7d7c7f !important;
    background-color: #7d7c7f !important;
}

.btn-success {
    color: #fff !important;
    height: 48px;
    font-weight: bold !important;
    border-width: 3px !important;
    border-style: solid !important;
    border-radius: 0 !important;
    border-color: #37afc9 !important;
    background-color: #37afc9 !important;
    margin-right: 15px !important;
    margin-top: 20px;
}

.btn-success:hover, .btn-success:focus {
    color: black !important;
    border-color: #37afc9 !important;
    background-color: #37afc9 !important;
}

.btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-primary.disabled, .btn-primary:disabled {
    color: #fff !important;
    opacity: .4 !important;
    border-color: #37afc9 !important;
    background-color: #37afc9 !important;
    cursor: unset !important;
}

.clear-button {
    color: #e35050 !important;
    font-weight: bold !important;
    border-width: 3px !important;
    border-style: solid !important;
    border-radius: 0 !important;
    border-color: #e35050 !important;
    background-color: #ffffff !important;
    margin-right: 15px !important;
    margin-top: 20px !important;
    height: 48px;
}

.clear-button:hover, .clear-button:focus {
    color: #a82424 !important;
    border-color: #a82424 !important;
    background-color: #fff !important;
}

.clear-button[disabled], .clear-button[disabled]:hover, .clear-button[disabled]:focus {
    color: #fff !important;
    opacity: .4 !important;
    border-color: #e35050 !important;
    background-color: #e35050 !important;
    cursor: unset !important;
}

.react-formik-ui .form-element .dropzone-wrapper .dropzone {
    box-sizing: border-box;
    border: 2px dotted #cdcdcd !important;
    background-color: #ffffff !important;
}

.react-formik-ui .form-element .dropzone-wrapper .dropzone .icon-wrapper {
    width: 100% !important;
    margin: 0 5px 0 5px !important;
}

.react-formik-ui .form-element .dropzone-wrapper .dropzone .icon-wrapper p {
    font-size: 14px !important;
    text-align: left !important;
}
.dropzone p.text {
    margin-top: 11px;
}

.react-formik-ui .form-element .dropzone-wrapper .dropzone .icon-wrapper .icon {
    height: 55px;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    background-color: #37afc9 !important;
    box-sizing: border-box;
    overflow: hidden;
    width: 40px;
    display: inline-block;
    float: left;
    margin-right: 10px;
}

.form-group {
    text-align: left !important;
}

.react-datepicker__close-icon::after {
    background-color: transparent !important;
    color: #37afc9 !important;
    font-size: 28px !important;
}

/*.custom-control-label::before {*/
/*    color: #dee2e6;*/
/*    border-color: #bd2130;*/
/*    background-color: #17a2b8 !important;*/
/*}*/

/*.custom-control-label::after {*/
/*    color: #dee2e6;*/
/*    border-color: #bd2130;*/
/*    background-color: #bd2130 !important;*/
/*}*/
.containerForm {
    display: block;
}


/* Hunderasse-Selektor*/
.css-1edacwf-control {
    border-radius: 0 !important;
    border-style: solid;
    border-width: 0 !important;
    border-bottom-width: 1px !important;
}

form, .containerForm {
    margin-top: 20px;
    padding: 0 20px 0 20px;
    box-sizing: border-box;
}

@media (min-width: 576px) {
    form, .containerForm {
        padding: 0 20px 0 20px;
    }

    .spezielleHunderasseSpalte {
        padding-left: 15px !important;
        text-align: left !important;
    }

    .spezielleHunderasseSpalteWithBtn {
        padding-top: 10px !important;
        padding-left: 15px !important;
        text-align: left !important;
    }
}

@media (min-width: 768px) {
    form, .containerForm {
        padding: 0 75px 0 75px;
    }

    .spezielleHunderasseSpalte {
        padding-left: 27px !important;
        text-align: left !important;
    }

    .spezielleHunderasseSpalteWithBtn {
        padding-top: 3px !important;
        padding-right: 15px !important;
        text-align: left !important;
    }
}

@media (min-width: 992px) {
    form, .containerForm {
        padding: 0 150px 0 150px;
    }

    .spezielleHunderasseSpalte {
        padding-left: 27px !important;
        text-align: left !important;
    }

    .spezielleHunderasseSpalteWithBtn {
        padding-top: 3px !important;
        padding-right: 15px !important;
        text-align: right !important;
    }
}

.custom-control {
    z-index: 0;
    background: #ececec;
    padding: 12px 43px;
    height: 48px;
    width: 100%;
}

.custom-control-label {
    line-height: 1.6rem;
}

.custom-switch .custom-control-label::before {
    float: right;
}

.unfallUl {
    display: inline
}

.unfallUl li {
    margin-left: 20px
}
.coloredLink,
.coloredLink:hover,
.coloredLink:focus
.coloredLink:link
.coloredLink:visited{
  color: #37afc9 !important;
    font-weight: bold;
    font-size: 15px;
}
.dottedBox {
    padding: 10px;
    border: 2px dotted #ececec
}
.form-check-input{
    margin-top: 0;
}

/* ACHTUNG MEGA-HACK um die Switcher auf die Volle Spalte zu ziehen.*/
.col-sm-8 .row-cols-md-2 .col-md-8{

    flex: 0 0 100%;
    max-width: 100%;
}
.col-md-8 .row-cols-md-2 .col-md-8{
    flex: 0 0 100%;
    max-width: 100%;
}
