@import 'basic.css';
@import 'wwfont-font.css';
@import 'adam-riese-font.css';
@import 'navi.css';
@import 'stepper-navi.css';
@import 'datepicker.css';
@import 'form.css';
@import 'spinner.css';

@page {
    margin-right: 2cm;
}

@media screen {
    .printComponentMediaScreen {
        margin: 50px;
        text-align: center;
    }

    .printComponentMediaPrint {
        display: none;
    }
}

@media print {

    html, body {
        color-adjust: exact;
        -webkit-print-color-adjust: exact;
        height: initial !important;
        overflow: initial !important;
    }

    .printComponentMediaScreen {
        display: none;
    }

    .printComponentMediaPrint {
        display: block;
    }

    .tariffPricesInformationBox {
        border: 2px dotted #ececec;
        margin-top: 40px;
    }

    .tariffPricesInformationBox p {
        margin: 10px;
    }

    .dontBrake {
        float: none !important;
        overflow: visible !important;
        break-before: auto !important;
        break-inside: avoid !important;
    }

    .pageBreakInTable {
        padding-top: 1cm;
        break-before: left !important;
    }

    .tariffHighlights {
        padding-bottom: 130px;
    }

    ul.calculationWrapper {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        text-indent: 0;
        margin-bottom: 0;
        display: block;
    }

    .praemieZahlweise {
        display: none;
    }

    .btn {
        display: none;
    }

    .calculationWrapperRow {
        margin: 0 0 20px 0 !important;
    }
    .calculationWrapperCell {
        flex-grow: 1;
    }


    .ar-icon {
        display: none;
    }

    .printComponent {
        margin-bottom: 50mm;
        width: 100%;
        height: 100%;
    }

    .calculationWrapperCell {
        width: 60mm;
    }

    .markBox .calculationWrapperBorder{
        border:  2px solid #4fd4e7;
    }

    .calculationWrapperHead {
        font-family: 'WWType', sans-serif !important;
        color: #495057 !important;
        font-size: 20pt;
        font-weight: bold;
    }
    .markBox .calculationWrapperHead{
        background-color:  #4fd4e7;
        color: #495057;
    }


    ul.calculationWrapper {
        padding-right: 10px;
        padding-left: 10px;
    }

    .markDisabled {
        color: #808080 !important;
    }

    .praemieSum {
        font-family: 'WWType', sans-serif !important;
        font-weight: bold;
        display: table;
        margin: 0 auto;
    }

    .calculationWrapperBody {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .letterEnd {
        position: fixed;
        bottom: 1cm;
        left: 3cm;
        right: 0;
        font-size: 12px;
    }

    .letterEnd table#center {
        text-align: center;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .letterEnd td {
        margin: auto;
        text-align: left;
    }

    .ar-logo-header img {
        width: 15%;
        margin: 20px;
    }

    .pdfCalculationData {
        padding: 10px 55px 10px 110px;
    }

    .pdfCalculationData hr {
        border-top: 1px solid #e1dfdf !important;
        height: 1px;
        margin-bottom: 0;
        margin-left: 0;
        margin-top: 20px;
    }

    .pdfCalculationData table {
        width: 100%;
    }

    .pdfCalculationData td {
        font-size: 15px;
        padding: 8px 0;
        width: 25%;
    }

    .pageMarginTop {
        height: 10px;
        display: inline-block;
    }

    .headerOffer {
        margin-top: 50px;
        margin-bottom: 100px;
    }

    .headerOffer p.top {
        margin: auto;
        font-size: 20px;
    }

    .headerOffer span.marker {
        float: left;
    }

    .headerOffer .marker {
        font-size: 30px;
    }

    .headerTariffs {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .headerTariffs span {
        font-size: 20px;
    }

    .marker {
        display: inline-block;
        position: relative;
        z-index: 1;
        margin-right: 2px;
    }

    .marker .marker-background {
        background-color: #4fd4e7 !important;
        position: absolute;
        display: inline-block;
        top: 28%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -2;
        height: 55%;
    }

    .marker .marker-background .bkg-left, .marker .marker-background .bkg-right {
        position: absolute;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        z-index: -1;
    }

    .marker .marker-background .bkg-left {
        background-image: url(../img/left_highlight.svg);
        background-position: 100% 0;
        height: 100%;
        left: 0;
        transform: translateX(-25%);
    }

    .marker .marker-background .bkg-right {
        background-image: url(../img/right_highlight.svg);
        background-position: 0 0;
        height: 100%;
        right: 0;
        transform: translateX(30%);
    }

    .markAsChoosen {
        border: 2px solid #4fd4e7;
    }

    .zusaetzlicheBausteine, zusaetzlicheBausteineRow {
        display: none;
    }
}
