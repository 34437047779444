
body {
    font-family: 'WWType', sans-serif !important;
    line-height: 1.3;
    font-size: 16px;
}

h1, h2 {
    font-family: 'PT Serif', sans-serif;
    font-weight: 900;
    text-align: left;
    margin-top: 30px !important;
}

h3.dialogHead{
    font-family: 'PT Serif', sans-serif;
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    width:100%
}
h3{
    font-family: 'WWType-Bold', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    margin-top: 30px !important;
    margin-bottom: 20px !important;
}

h4 {
    font-size: 43px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 0 !important;
    margin-top: 40px !important;
}

h1.anmeldung {
    text-align: center;
}

h4.anmeldung {
    text-align: center;
    width: 100%;
}

h4 {
    font-size: 24px;
}
.modalFooter {
    display: block !important;
    text-align: center !important;
    padding-bottom: 20px !important;
}

a {
    color: #495057 !important;
}

.cursorHand {
    cursor: pointer;
}
.modal-header .ar-icon-cross {
    position: absolute;
    right: 20px;
}
.list-group-item {
    padding: 0.75rem 0 !important;
}

.cursor-link {
    cursor: pointer;
}

.container {
    width: 100%;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.card {
    border: 0 !important;
    border-radius: 0 !important;
    margin-top: 55px;
    margin-bottom: 60px;
}

.error {
    margin-top: 0.25rem;
    font-size: 80%;
    color: rgb(220, 53, 69);
}

.loginData {
    font-family: 'WWType', sans-serif !important;
    font-size: 12px;
    color: gray;
    float: right;
}

.loginDataNr {
    font-family: 'WWType', sans-serif !important;
    display: none;
}

.alignCenter {
    text-align: center;
}

.anmeldeDiv {
    margin: auto;
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
}

.uploadBar {
    display: inline-block !important;
    padding: 10px 20px;
    margin-right: 0 !important;
    box-sizing: border-box;
    margin-left: 0;
    text-align: center !important;
    width: 100%;
}

.uploadLink {
    color: #37afc9;
    cursor: pointer;
    font-size: 12px;
    margin-right: 15px;
}
.downloadLink {
    color: #37afc9 !important;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none !important;
    position: relative;
    padding-left: 25px;
}
.downloadLink:hover {
    text-decoration: none !important;
}
.downloadLink .ar-icon-download{
    position: absolute;
    left: auto;
    margin-left: -36px;
    top: -1px;
}
.downloadLink .ar-icon-download::before{
    margin-top:3px;
}

.uploadLink .ar-btn::before {
    font-size: 25px;
}

.modalUpload {
    padding: 30px 0;
    text-align: center;
}

.marker {
    display: inline-block;
    position: relative;
    z-index: 1;
    margin-right: 10px;
}

.marker .marker-background {
    background-color: #4fd4e7;
    position: absolute;
    display: inline-block;
    top: 28%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    height: 55%;
}

.marker .marker-background .bkg-left, .marker .marker-background .bkg-right {
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
}

.marker .marker-background .bkg-left {
    background-image: url(../img/left_highlight.svg);
    background-position: 100% 0;
    height: 100%;
    left: 0;
    transform: translateX(-25%);
}

.marker .marker-background .bkg-right {
    background-image: url(../img/right_highlight.svg);
    background-position: 0 0;
    height: 100%;
    right: 0;
    transform: translateX(30%);
}

.list-group-item:first-child {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.list-group-item:first-child {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 0 !important;
    text-align: left;
}

.modal-header {
    border-bottom: 0 !important;
}

.modal-footer {
    border-top: 0 !important;
}

.loginData {
    top: 37px;
}

.loginData .fa {
    font-size: 24px;
}

.praemieDiv {
    width: 100%;
    border-top: 10px solid #ececec;
    padding: 10px 20px;
    text-align: right;
    box-sizing: border-box;
}

.praemieSum {
    font-family: 'PT Serif', sans-serif !important;
    font-size: 30px;
    font-weight: bold;
    padding-right: 10px;
}

.praemieZahlweise {
    font-size: 18px;
}

.praemieHinweis {
    font-size: 12px;
    width: 100%;
    padding-top: 5px;
    display: block;
}

.list-group-item.performanceOverviewLink {
    text-align: center !important;
}

.modal-content {
    border: 0 !important;
}

.modal-dialog {
    max-width: 100% !important;
}

.anmeldeDialog {
    text-align: center;
}

.anmeldeDialog .modal-content {
    width: 70%;
    margin: auto;
}

.btn {
    color: #ececec !important;
    background-color: #37afc9 !important;
}

.Toastify__toast--dark {
    background: #121212 !important;
    color: #fff !important;
}

.Toastify__toast--default {
    background: #fff !important;
    color: #aaa !important;
}

.Toastify__toast--info {
    background: #37afc9 !important;
}

.Toastify__toast--success {
    background: #37afc9 !important;
}

.Toastify__toast--warning {
    background: #f1840f !important;
}

.Toastify__toast--error {
    background: #bf1414 !important;
}

.Toastify__toast-body {
    margin: auto 0;
    flex: 1 1 auto;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #37afc9 !important;
    background-color: #37afc9 !important;
}

.calculationWrapperHead {
    font-family: 'WWType-Bold', sans-serif;
    font-size: 20px;
    flex-grow: 0;
    padding:20px 12px;
    display: block;
    background-color: #ececec;
    color: #495057
}

.calculationWrapperBody {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.calculationTooltip {
    cursor: pointer;
}
.ar-btn.ar-icon-info{
    color: #37afc9;
}

.bs-tooltip-right .arrow:before{
    border-right-color: #37afc9 !important;
}
.bs-tooltip-left .arrow:before{
    border-left-color: #37afc9 !important;
}
.bs-tooltip-top .arrow:before{
    border-top-color: #37afc9 !important;
}
.bs-tooltip-bottom .arrow:before{
    border-bottom-color: #37afc9 !important;
}
@media (min-width: 992px){
    .rightCol {
        padding-left: 0;
    }
}
.tooltip-inner{
    background-color: #37afc9;
    text-align: left;
}
.calculationWrapperSubHead {
    height: 50px;
    width: 100%;
    display: inline-block;
    flex-grow: 0;
}
.zusatzBausteinRadio {
    border: none;
    float: left;
    width: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
    position: absolute;
    left: 68px;
    top: 22px;
}
.zusatzBausteinHead {
    margin:22px 0 0 30px;
    font-weight: bold;
}

.calculationWrapperRow {
    margin: 0 0 20px 0 !important;
}
.calculationWrapperBorder{
    border: 1px solid rgba(0,0,0,.125);
    margin-left: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.calculationWrapperCell {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
}
ul.calculationWrapper {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
    text-indent: -1.4em;
    margin-bottom: 0;
    display: block;
}


ul.calculationWrapper li {
    padding: 7px 0;

}

.flexGrow {
    flex-grow: 1;
}

ul.calculationWrapperDisabled, ul.calculationWrapperDisabled .ar-icon::before {
    color: #a8a8a8;
}

@media (min-width: 576px) {


    ul.calculationWrapper {
        list-style: none;
        margin-left: 0;
        padding-left: 1em;
        text-indent: -1.4em;
        margin-bottom: 0;
        display: block;
    }

    .modal-dialog {
        max-width: 100% !important;
    }

    .container, .container-lg, .container-md, .container-sm {
        max-width: 100% !important;
    }

    .loginDataNr {
        display: none;
    }

    .loginData {
        top: 37px;
    }

    .loginData .fa {
        font-size: 24px;
    }

    .anmeldeDiv {
        width: 100%;
    }

    .uploadBar {
        text-align: center !important;
    }
    .uploadLink .ar-btn::before {
        font-size: 25px;
    }

    .praemieDiv {
        padding: 10px 20px;
    }

}

@media (min-width: 768px) {

    ul.calculationWrapper {
        list-style: none;
        margin-left: 0;
        padding-left: 1em;
        text-indent: -1.4em;
        margin-bottom: 0;
        display: block;
    }

    .modal-dialog {
        max-width: 70% !important;
    }

    .container, .container-lg, .container-md, .container-sm {
        max-width: 100% !important;
    }


    .loginDataNr {
        display: none;
    }

    .loginData {
        top: 37px;
    }

    .loginData .fa {
        font-size: 24px;
    }

    .anmeldeDiv {
        width: 600px;
    }

    .uploadBar {
        text-align: right !important;
    }


    .praemieDiv {
        padding: 10px 75px;
    }
}


.uploadText {
    display: inline-block;
    font-weight: bold;
}

.uploadLink {
    position: relative;
}

.uploadLink .ar-btn::before {
    font-size: 15px;
    padding-right: 5px;
}

.uploadLink .ar-icon-upload {
    position: absolute;
    left: -33px;
    top: -13px
}


.App {
    max-width: 1170px;
    position: relative;
    flex-grow: 1;
    flex-basis: auto;
}
.site-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
}
.site-footer {
    background-color: #ececec;
    width: 100%;
    height:100px;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    color:gray;
    line-height:100px;
    text-align: center;
}
.site-footer a,
.site-footer a:link,
.site-footer a:visited,
.site-footer a:hover,
.site-footer a:focus {
    color:gray !important;
}
.anmeldung-top{
    width: 100%;
    height:40px;
    padding: 0 120px 0 120px;
    text-align: center;
}

@media (min-width: 992px) {

    .anmeldung-top{
        text-align: left;
    }
    .App {
        width: 1170px;
    }
    ul.calculationWrapper {
        list-style: none;
        margin-left: 0;
        padding-left: 1em;
        text-indent: -1.4em;
        margin-bottom: 0;
        display: block;
    }

    .modal-dialog {
        max-width: 600px !important;
    }

    .container, .container-lg, .container-md, .container-sm {
        max-width: 100% !important;
    }

    .loginDataNr {
        display: inline-block;
    }


    .loginData {
        top: 95px;
    }

    .loginData .fa {
        font-size: inherit;
    }

    .anmeldeDiv {
        width: 70%;
    }

    .uploadBar {
        text-align: right !important;
    }
    .praemieDiv {
        padding: 10px 150px;
    }
}

.markAsChoosen {
    border: 2px solid #4fd4e7;
}

.zusaetzlicheBausteine {
    border: 2px solid #ececec;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}
